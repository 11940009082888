import React, { useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";

const ModalPage = () => {
  const [modalopen, setModalOpen] = useState({
    gizlilik: false,
    bilgilendirme: false,
    iptaliade: false,
    iletisim: false,
  });

  return (
    <>
      <Modal
        open={modalopen.gizlilik}
        onClose={() => setModalOpen((prev) => ({ ...prev, gizlilik: false }))}
        center
      >
        <div className="px-2 py-3">
          <p className="mb-2 text-lg">
            Konya - Türkiye adresinde kayıtlı MAY PLASTİK firmamıza aittir ve
            firmamız tarafından işletilir.
          </p>

          <p className="mb-2">
            Firmamız, çeşitli amaçlarla kişisel veriler toplayabilir. Aşağıda,
            toplanan kişisel verilerin nasıl ve ne şekilde toplandığı, bu
            verilerin nasıl ve ne şekilde korunduğu belirtilmiştir.
          </p>

          <p className="mb-2">
            Üyelik veya Mağazamız üzerindeki çeşitli form ve anketlerin
            doldurulması suretiyle üyelerin kendileriyle ilgili bir takım
            kişisel bilgileri (isim-soy isim, firma bilgileri, telefon, adres
            veya e-posta adresleri gibi) Mağazamız tarafından işin doğası gereği
            toplanmaktadır.
          </p>

          <p className="mb-2">
            Firmamız bazı dönemlerde müşterilerine ve üyelerine kampanya
            bilgileri, yeni ürünler hakkında bilgiler, promosyon teklifleri
            gönderebilir. Üyelerimiz bu gibi bilgileri alıp almama konusunda her
            türlü seçimi üye olurken yapabilir, sonrasında üye girişi yaptıktan
            sonra hesap bilgileri bölümünden bu seçimi değiştirilebilir ya da
            kendisine gelen bilgilendirme iletisindeki linkle bildirim
            yapabilir.
          </p>

          <p className="mb-2">
            Mağazamız üzerinden veya eposta ile gerçekleştirilen onay sürecinde,
            üyelerimiz tarafından mağazamıza elektronik ortamdan iletilen
            kişisel bilgiler, Üyelerimiz ile yaptığımız "Kullanıcı Sözleşmesi"
            ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere
            açıklanmayacaktır.
          </p>

          <p className="mb-2">
            Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili
            çıkabilecek sorunların veya uyuşmazlıkların hızla çözülmesi için,
            Firmamız, üyelerinin IP adresini kaydetmekte ve bunu kullanmaktadır.
            IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı
            demografik bilgi toplamak amacıyla da kullanılabilir.
          </p>

          <p className="mb-2">
            Firmamız, Üyelik Sözleşmesi ile belirlenen amaçlar ve kapsam dışında
            da, talep edilen bilgileri kendisi veya işbirliği içinde olduğu
            kişiler tarafından doğrudan pazarlama yapmak amacıyla kullanabilir.
            Kişisel bilgiler, gerektiğinde kullanıcıyla temas kurmak için de
            kullanılabilir. Firmamız tarafından talep edilen bilgiler veya
            kullanıcı tarafından sağlanan bilgiler veya Mağazamız üzerinden
            yapılan işlemlerle ilgili bilgiler; Firmamız ve işbirliği içinde
            olduğu kişiler tarafından, "Üyelik Sözleşmesi" ile belirlenen
            amaçlar ve kapsam dışında da, üyelerimizin kimliği ifşa edilmeden
            çeşitli istatistiksel değerlendirmeler, veri tabanı oluşturma ve
            pazar araştırmalarında kullanılabilir.
          </p>

          <p className="mb-2">
            Firmamız, gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir
            sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve
            sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının
            kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir
            kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve
            gerekli özeni göstermeyi taahhüt etmektedir.
          </p>

          <h2 className="mb-2 font-semibold">KREDİ KARTI GÜVENLİĞİ</h2>
          <p className="mb-2">
            Firmamız, alışveriş sitelerimizden alışveriş yapan kredi kartı
            sahiplerinin güvenliğini ilk planda tutmaktadır. Kredi kartı
            bilgileriniz hiçbir şekilde sistemimizde saklanmamaktadır.
          </p>

          <p className="mb-2">
            İşlemler sürecine girdiğinizde güvenli bir sitede olduğunuzu anlamak
            için dikkat etmeniz gereken iki şey vardır. Bunlardan biri
            tarayıcınızın en alt satırında bulunan bir anahtar ya da kilit
            simgesidir. Bu güvenli bir internet sayfasında olduğunuzu gösterir
            ve her türlü bilgileriniz şifrelenerek korunur. Bu bilgiler, ancak
            satış işlemleri sürecine bağlı olarak ve verdiğiniz talimat
            istikametinde kullanılır. Alışveriş sırasında kullanılan kredi kartı
            ile ilgili bilgiler alışveriş sitelerimizden bağımsız olarak 128 bit
            SSL (Secure Sockets Layer) protokolü ile şifrelenip sorgulanmak
            üzere ilgili bankaya ulaştırılır. Kartın kullanılabilirliği
            onaylandığı takdirde alışverişe devam edilir. Kartla ilgili hiçbir
            bilgi tarafımızdan görüntülenemediğinden ve kaydedilmediğinden,
            üçüncü şahısların herhangi bir koşulda bu bilgileri ele geçirmesi
            engellenmiş olur.
          </p>

          <p className="mb-2">
            Online olarak kredi kartı ile verilen siparişlerin
            ödeme/fatura/teslimat adresi bilgilerinin güvenilirliği firmamız
            tarafından Kredi Kartları Dolandırıcılığı'na karşı denetlenmektedir.
            Bu yüzden, alışveriş sitelerimizden ilk defa sipariş veren
            müşterilerin siparişlerinin tedarik ve teslimat aşamasına
            gelebilmesi için öncelikle finansal ve adres/telefon bilgilerinin
            doğruluğunun onaylanması gereklidir. Bu bilgilerin kontrolü için
            gerekirse kredi kartı sahibi müşteri ile veya ilgili banka ile
            irtibata geçilmektedir.
          </p>

          <p className="mb-2">
            Üye olurken verdiğiniz tüm bilgilere sadece siz ulaşabilir ve siz
            değiştirebilirsiniz. Üye giriş bilgilerinizi güvenli koruduğunuz
            takdirde başkalarının sizinle ilgili bilgilere ulaşması ve bunları
            değiştirmesi mümkün değildir. Bu amaçla, üyelik işlemleri sırasında
            128 bit SSL güvenlik alanı içinde hareket edilir. Bu sistem
            kırılması mümkün olmayan bir uluslararası bir şifreleme
            standardıdır.
          </p>

          <p className="mb-2">
            Bilgi hattı veya müşteri hizmetleri servisi bulunan ve açık adres ve
            telefon bilgilerinin belirtildiği İnternet alışveriş siteleri
            günümüzde daha fazla tercih edilmektedir. Bu sayede aklınıza takılan
            bütün konular hakkında detaylı bilgi alabilir, online alışveriş
            hizmeti sağlayan firmanın güvenirliği konusunda daha sağlıklı bilgi
            edinebilirsiniz.
          </p>

          <p className="mb-2">
            Not: İnternet alışveriş sitelerinde firmanın açık adresinin ve
            telefonun yer almasına dikkat edilmesini tavsiye ediyoruz. Alışveriş
            yapacaksanız alışverişinizi yapmadan ürünü aldığınız mağazanın bütün
            telefon / adres bilgilerini not edin. Eğer güvenmiyorsanız
            alışverişten önce telefon ederek teyit edin. Firmamıza ait tüm
            online alışveriş sitelerimizde firmamıza dair tüm bilgiler ve firma
            yeri belirtilmiştir.
          </p>

          <h2 className="mb-2 font-semibold">
            MAİL ORDER KREDİ KART BİLGİLERİ GÜVENLİĞİ
          </h2>
          <p className="mb-2">
            Kredi kartı mail-order yöntemi ile bize göndereceğiniz kimlik ve
            kredi kart bilgileriniz firmamız tarafından gizlilik prensibine göre
            saklanacaktır. Bu bilgiler olası banka ile oluşabilecek kredi
            kartından para çekim itirazlarına karşı 60 gün süre ile bekletilip
            daha sonrasında imha edilmektedir. Sipariş ettiğiniz ürünlerin
            bedeli karşılığında bize göndereceğiniz tarafınızdan onaylı
            mail-order formu bedeli dışında herhangi bir bedelin kartınızdan
            çekilmesi halinde doğal olarak bankaya itiraz edebilir ve bu tutarın
            ödenmesini engelleyebileceğiniz için bir risk oluşturmamaktadır.
          </p>

          <h2 className="mb-2 font-semibold">
            ÜÇÜNCÜ TARAF WEB SİTELERİ VE UYGULAMALAR
          </h2>
          <p className="mb-2">
            Mağazamız, web sitesi dâhilinde başka sitelere link verebilir.
            Firmamız, bu linkler vasıtasıyla erişilen sitelerin gizlilik
            uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk
            taşımamaktadır. Firmamıza ait sitede yayınlanan reklamlar,
            reklamcılık yapan iş ortaklarımız aracılığı ile kullanıcılarımıza
            dağıtılır. İş bu sözleşmedeki Gizlilik Politikası Prensipleri,
            sadece Mağazamızın kullanımına ilişkindir, üçüncü taraf web
            sitelerini kapsamaz.
          </p>

          <h2 className="mb-2 font-semibold">İSTİSNAİ HALLER</h2>
          <p className="mb-2">
            Aşağıda belirtilen sınırlı hallerde Firmamız, işbu "Gizlilik
            Politikası" hükümleri dışında kullanıcılara ait bilgileri üçüncü
            kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;
          </p>
          <ol className="mb-2">
            <li className="mb-2">
              Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. yetkili hukuki
              otorite tarafından çıkarılan ve yürürlükte olan hukuk kurallarının
              getirdiği zorunluluklara uymak;
            </li>
            <li className="mb-2">
              Mağazamızın kullanıcılarla akdettiği "Üyelik Sözleşmesi"'nin ve
              diğer sözleşmelerin gereklerini yerine getirmek ve bunları
              uygulamaya koymak amacıyla;
            </li>
            <li className="mb-2">
              Yetkili idari ve adli otorite tarafından usulüne göre yürütülen
              bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla
              ilgili bilgi talep edilmesi;
            </li>
            <li className="mb-2">
              Kullanıcıların hakları veya güvenliklerini korumak için bilgi
              vermenin gerekli olduğu hallerdir.
            </li>
          </ol>

          <h2 className="mb-2 font-semibold">E-POSTA GÜVENLİĞİ</h2>
          <p className="mb-2">
            Mağazamızın Müşteri Hizmetleri'ne, herhangi bir siparişinizle ilgili
            olarak göndereceğiniz e-postalarda, asla kredi kartı numaranızı veya
            şifrelerinizi yazmayınız. E-postalarda yer alan bilgiler üçüncü
            şahıslar tarafından görülebilir. Firmamız e-postalarınızdan
            aktarılan bilgilerin güvenliğini hiçbir koşulda garanti edemez.
          </p>

          <h2 className="mb-2 font-semibold">TARAYICI ÇEREZLERİ</h2>
          <p className="mb-2">
            Firmamız, mağazamızı ziyaret eden kullanıcılar ve kullanıcıların web
            sitesini kullanımı hakkındaki bilgileri teknik bir iletişim dosyası
            (Çerez-Cookie) kullanarak elde edebilir. Bahsi geçen teknik iletişim
            dosyaları, ana bellekte saklanmak üzere bir internet sitesinin
            kullanıcının tarayıcısına (browser) gönderdiği küçük metin
            dosyalarıdır. Teknik iletişim dosyası site hakkında durum ve
            tercihleri saklayarak İnternet'in kullanımını kolaylaştırır.
          </p>

          <p className="mb-2">
            Teknik iletişim dosyası, siteyi kaç kişinin ziyaret ettiğini, bir
            kişinin siteyi hangi amaçla, kaç kez ziyaret ettiğini ve ne kadar
            sitede kaldıkları hakkında istatistiksel bilgileri elde etmeye ve
            kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik
            olarak reklam ve içerik üretilmesine yardımcı olur. Teknik iletişim
            dosyası, ana bellekte veya e-postanızdan veri veya başkaca herhangi
            bir kişisel bilgi almak için tasarlanmamıştır. Tarayıcıların pek
            çoğu başta teknik iletişim dosyasını kabul eder biçimde
            tasarlanmıştır ancak kullanıcılar dilerse teknik iletişim dosyasının
            gelmemesi veya teknik iletişim dosyasının gönderildiğinde uyarı
            verilmesini sağlayacak biçimde ayarları değiştirebilirler.
          </p>

          <p className="mb-2">
            Firmamız, işbu "Gizlilik Politikası" hükümlerini dilediği zaman
            sitede yayınlamak veya kullanıcılara elektronik posta göndermek veya
            sitesinde yayınlamak suretiyle değiştirebilir. Gizlilik Politikası
            hükümleri değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır.
          </p>

          <p className="mb-2">
            Gizlilik politikamız ile ilgili her türlü soru ve önerileriniz için{" "}
            <a href="mailto:bilgi@mayplastik.com">bilgi@mayplastik.com</a>{" "}
            adresine e-mail gönderebilirsiniz. Firmamız'a ait aşağıdaki iletişim
            bilgilerinden ulaşabilirsiniz.
          </p>

          <p className="mb-2">
            <strong>MAY PLASTİK</strong>
            <br />
            KONYA - Türkiye
          </p>
        </div>
      </Modal>
      <Modal
        open={modalopen.bilgilendirme}
        onClose={() =>
          setModalOpen((prev) => ({ ...prev, bilgilendirme: false }))
        }
        center
      >
        <div className="mb-4">
          <h2 className="font-bold mb-2">1. KONU</h2>
          <p className="mb-2">
            İşbu Satış Sözleşmesi Ön Bilgi Formu’nun konusu, SATICI'nın, SİPARİŞ
            VEREN/ALICI'ya satışını yaptığı, aşağıda nitelikleri ve satış fiyatı
            belirtilen ürün/ürünlerin satışı ve teslimi ile ilgili olarak 6502
            sayılı Tüketicilerin Korunması Hakkındaki Kanun - Mesafeli
            Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri gereğince
            tarafların hak ve yükümlülüklerini kapsamaktadır. İş bu ön
            bilgilendirme formunu kabul etmekle ALICI, sözleşme konusu siparişi
            onayladığı takdirde sipariş konusu bedeli ve varsa kargo ücreti,
            vergi gibi belirtilen ek ücretleri ödeme yükümlülüğü altına
            gireceğini ve bu konuda bilgilendirildiğini peşinen kabul eder.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="font-bold mb-2">2. SATICI BİLGİLERİ</h2>
          <p className="mb-2">
            Ünvanı: MAY PLASTİK
            <br />
            Adresi: Tatlıcak Mahallesi Gürçınar Sokak No:60 Vatan Sanayi Sitesi
            Karatay - KONYA
            <br />
            Telefon: 0 332 342 16 66
            <br />
            Eposta: bilgi@mayplastik.com
          </p>
        </div>

        <div className="mb-4">
          <h2 className="font-bold mb-2">
            3. ALICI BİLGİLERİ (Bundan sonra ALICI olarak anılacaktır.)
          </h2>
          <p className="mb-2">
            Teslim edilecek kişi:
            <br />
            Teslimat adresi:
            <br />
            Telefon:
            <br />
            Fax:
            <br />
            Eposta/Kullanıcı adı:
          </p>
        </div>

        <div className="mb-4">
          <h2 className="font-bold mb-2">
            4. SİPARİŞ VEREN BİLGİLERİ (Bundan sonra SİPARİŞ VEREN olarak
            anılacaktır.)
          </h2>
          <p className="mb-2">
            Adı, soyadı/Ünvanı:
            <br />
            Adresi:
            <br />
            Telefon:
            <br />
            Fax:
            <br />
            Eposta/Kullanıcı adı:
          </p>
        </div>

        <div className="mb-4">
          <h2 className="font-bold mb-2">
            5. SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ
          </h2>
          <p className="mb-2">
            5.1- Malın / Ürün/Ürünlerin / Hizmetin temel özellikleri (türü,
            miktarı, marka/modeli, rengi, adedi) SATICI’ya ait internet
            sitesinde yer almaktadır. Ürünün temel özelliklerini kampanya
            süresince inceleyebilirsiniz. Kampanya tarihine kadar geçerlidir.
          </p>
          <p className="mb-2">
            5.2- Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan
            edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene
            kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen
            süre sonuna kadar geçerlidir.
          </p>
          <p className="mb-2">
            5.3- Ürün sevkiyat masrafı olan kargo ücreti ALICI tarafından
            ödenecektir.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="font-bold mb-2">6. GENEL HÜKÜMLER</h2>
          <p className="mb-2">
            6-1- ALICI, SATICI’ya ait internet sitesinde sözleşme konusu ürünün
            temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin
            ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda
            gerekli teyidi verdiğini kabul, beyan ve taahhüt eder. ALICININ; Ön
            Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış
            sözleşmesinin kurulmasından evvel, SATICI tarafından ALICI' ya
            verilmesi gereken adresi, siparişi verilen ürünlere ait temel
            özellikleri, ürünlerin vergiler dâhil fiyatını, ödeme ve teslimat
            bilgilerini de doğru ve eksiksiz olarak edindiğini kabul, beyan ve
            taahhüt eder.
          </p>
          <p className="mb-2">
            6-2- Sözleşme konusu her bir ürün, 40 günlük yasal süreyi aşmamak
            kaydı ile ALICI' nın yerleşim yeri uzaklığına bağlı olarak internet
            sitesindeki ön bilgiler kısmında belirtilen süre zarfında ALICI veya
            ALICI’ nın gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir.
            Bu süre içinde ürünün ALICI’ya teslim edilememesi durumunda,
            ALICI’nın sözleşmeyi feshetme hakkı saklıdır.
          </p>
          <p className="mb-2">
            6-3- SATICI, sözleşme konusu ürünü eksiksiz, siparişte belirtilen
            niteliklere uygun ve varsa garanti belgeleri, kullanım kılavuzları
            ile teslim etmeyi, her türlü ayıptan arî olarak yasal mevzuat
            gereklerine sağlam, standartlara uygun bir şekilde işin gereği olan
            bilgi ve belgeler ile işi doğruluk ve dürüstlük esasları dâhilinde
            ifa etmeyi, hizmet kalitesini koruyup yükseltmeyi, işin ifası
            sırasında gerekli dikkat ve özeni göstermeyi, ihtiyat ve öngörü ile
            hareket etmeyi kabul, beyan ve taahhüt eder.
          </p>
          <p className="mb-2">
            6-4- SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan
            ALICI’yı bilgilendirmek ve açıkça onayını almak suretiyle eşit
            kalite ve fiyatta farklı bir ürün tedarik edebilir.
          </p>
          <p className="mb-2">
            6-5- SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin
            imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine
            getiremezse, bu durumu, öğrendiği tarihten itibaren 3 gün içinde
            yazılı olarak tüketiciye bildireceğini, 14 günlük süre içinde toplam
            bedeli ALICI’ya iade edeceğini kabul, beyan ve taahhüt eder.
          </p>
          <p className="mb-2">
            6-6- ALICI, sözleşme konusu ürünün teslimatı için işbu Ön
            Bilgilendirme Formunu elektronik ortamda teyit edeceğini, herhangi
            bir nedenle sözleşme konusu ürün bedelinin ödenmemesi ve/veya banka
            kayıtlarında iptal edilmesi halinde, SATICI’ nın sözleşme konusu
            ürünü teslim yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt
            eder.
          </p>
          <p className="mb-2">
            6-7- ALICI, Sözleşme konusu ürünün ALICI veya ALICI’nın gösterdiği
            adresteki kişi ve/veya kuruluşa tesliminden sonra ALICI'ya ait kredi
            kartının yetkisiz kişilerce haksız kullanılması sonucunda sözleşme
            konusu ürün bedelinin ilgili banka veya finans kuruluşu tarafından
            SATICI'ya ödenmemesi halinde, ALICI Sözleşme konusu ürünü 3 gün
            içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya
            iade edeceğini kabul, beyan ve taahhüt eder.
          </p>
          <p>
            6-8- ALICI, kişiye özel olarak üretilen ürünlerin iadesinin
            yapılamayacağını kabul eder. Ancak, ürünün imalatında veya ürünün
            ölçülerinde bir hata olması ya da ürünün çalışmaması durumunda
            ALICI, bu durumu SATICI'ya bildirerek çözüm talebinde bulunabilir.
            Bu durumda, ALICI ve SATICI, ürünün iadesi veya değiştirilmesi
            konusunda ortak bir karara varmalıdır. ALICI, ürünün ambalajını
            açmadan ve teslim tarihinden itibaren 3 gün içinde iade talep etme
            hakkına sahiptir; ancak kişiye özel imalat nedeniyle, bu iadenin
            gerçekleşebilmesi için SATICI'nın onayını alması ve tarafların ortak
            karara varmaları gerekmektedir.
          </p>
          <p className="mb-2">
            6-9- SATICI, tarafların iradesi dışında gelişen, önceden
            öngörülemeyen ve tarafların borçlarını yerine getirmesini
            engelleyici ve/veya geciktirici hallerin oluşması gibi mücbir
            sebepler halleri nedeni ile sözleşme konusu ürünü süresi içinde
            teslim edemez ise, durumu ALICI' ya bildireceğini kabul, beyan ve
            taahhüt eder. ALICI da siparişin iptal edilmesini, sözleşme konusu
            ürünün varsa emsali ile değiştirilmesini ve/veya teslimat süresinin
            engelleyici durumun ortadan kalkmasına kadar ertelenmesini SATICI’
            dan talep etme hakkına haizdir. ALICI tarafından 6.8 deki şartlardan
            dolayı siparişin iptal edilmesi halinde ALICI’ nın nakit ile yaptığı
            ödemelerde, ürün tutarı 14 gün içinde kendisine nakden ve defaten
            ödenir. ALICI’ nın kredi kartı ile yaptığı ödemelerde ise, ürün
            tutarı, siparişin ALICI tarafından iptal edilmesinden sonra 14 gün
            içerisinde ilgili bankaya iade edilir. ALICI, SATICI tarafından
            kredi kartına iade edilen tutarın banka tarafından ALICI hesabına
            yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı
            bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’ nın
            hesaplarına yansıması halinin tamamen banka işlem süreci ile ilgili
            olduğundan, ALICI, olası gecikmeler için SATICI’ yı sorumlu
            tutamayacağını kabul, beyan ve taahhüt eder.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="font-bold mb-2">7. FATURA BİLGİLERİ</h2>
          <p className="mb-2">
            Ad/Soyad/Unvan <br />
            Adres
            <br />
            Telefon
            <br />
            Faks
            <br />
            Eposta/kullanıcı adı
            <br />
            Fatura teslim : Fatura sipariş teslimatı sırasında fatura adresine
            sipariş ile birlikte teslim edilecektir.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="font-bold mb-2">8. CAYMA HAKKI</h2>
          <p className="mb-2">
            8.1. ALICI, kişiye özel olarak üretilen ürünlerde cayma hakkının
            bulunmadığını kabul ve taahhüt eder. Ancak, ürünün imalatında veya
            ürünün ölçülerinde SATICI’dan kaynaklanan bir hata bulunması ya da
            ürünün çalışmaması durumunda, ALICI bu durumu SATICI'ya bildirerek
            çözüm talebinde bulunabilir. Bu durumda, taraflar, ürünün iadesi,
            onarımı veya değiştirilmesi konularında ortak bir karara varmak için
            iletişime geçeceklerdir. ALICI, ürünün ambalajını açmamış olması
            kaydıyla, teslim tarihinden itibaren 3 (üç) gün içinde iade
            talebinde bulunma hakkına sahiptir. Ancak, ürünlerin kişiye özel
            imal edilmesi sebebiyle, bu iade talebinin geçerli sayılabilmesi
            için ALICI, öncelikle SATICI’nın onayını almak ve tarafların bu
            konuda karşılıklı mutabakata varmaları gerektiğini kabul eder.
            SATICI, bu onayı vermekte serbesttir ve talepleri değerlendirerek
            ürünün iade süreci hakkında son kararı verme hakkına sahiptir. Cayma
            hakkının kullanımıyla ilgili tüm masraflar, koşullara göre SATICI’ya
            ait olup, ALICI, bu sözleşmeyi onaylamakla cayma hakkı ve iade
            koşulları hakkında tam olarak bilgilendirildiğini kabul, beyan ve
            taahhüt eder.
          </p>
          <p className="mb-2">
            8-2- Cayma hakkının kullanılması için 3 (üç) günlük süre içinde
            SATICI' ya iadeli taahhütlü posta, faks veya eposta ile yazılı
            bildirimde bulunulması veya SATICI ile iletişime geçmesi ve ürünün
            işbu sözleşmede düzenlenen "Cayma Hakkı Kullanılamayacak Ürünler"
            hükümleri çerçevesinde kullanılmamış olması şarttır. Bu hakkın
            kullanılması halinde,
          </p>
          <p className="mb-2">
            8-2-1 kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade
            edilmek istenen ürünün faturası kurumsal ise, geri iade ederken
            kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi
            gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri
            İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
          </p>
          <p className="mb-2">8-2-2 İade formu,</p>
          <p className="mb-2">
            8-2-3 İade edilecek ürünlerin kutusu, ambalajı, varsa standart
            aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim
            edilmesi gerekmektedir.
          </p>
          <p className="mb-2">
            8-2-4 SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en
            geç 15 günlük süre içerisinde toplam bedeli ve ALICI’ yı borç altına
            sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde
            malı iade almakla yükümlüdür.
          </p>
          <p className="mb-2">
            8-2-5 ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde
            bir azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında
            SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı
            süresi içinde malın veya ürünün usulüne uygun kullanılmasın
            sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu
            değildir.
          </p>
          <p className="mb-2">
            8-2-6 Cayma hakkının kullanılması nedeniyle SATICI tarafından
            düzenlenen kampanya limit tutarının altına düşülmesi halinde
            kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
          </p>
        </div>
        <div className="mb-4">
          <h2 className="font-bold mb-2">
            9. CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER
          </h2>
          <p className="mb-2">
            9-1-a- Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak
            değişen ve satıcı veya sağlayıcının kontrolünde olmayan mal veya
            hizmetlere ilişkin sözleşmeler.
            <br />
            b- Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda
            hazırlanan mallara ilişkin sözleşmeler.
            <br />
            c- Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların
            teslimine ilişkin sözleşmeler.
            <br />
            ç- Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
            unsurları açılmış olan mallardan; iadesi sağlık vehijyenaçısından
            uygun olmayanların teslimine ilişkin sözleşmeler.
            <br />
            d- Tesliminden sonra başka ürünlerle karışan ve doğası gereği
            ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler.
            <br />
            e- Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
            unsurları açılmış olması halinde maddi ortamda sunulan kitap,
            dijital içerik ve bilgisayar sarf malzemelerine, veri kaydedebilme
            ve veri depolama cihazlarına ilişkin sözleşmeler.
            <br />
            f- Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve
            dergi gibi süreli yayınların teslimine ilişkin sözleşmeler.
            <br />
            g- Belirli bir tarihte veya dönemde yapılması gereken, konaklama,
            eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya
            dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin
            sözleşmeler.
            <br />
            ğ- Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye
            anında teslim edilengayrimaddimallara ilişkin sözleşmeler.
            <br />
            h- Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile
            ifasına başlanan hizmetlere ilişkin sözleşmeler.
            <br />
            Kozmetik ve kişisel bakım ürünleri, iç giyim ürünleri, mayo, bikini,
            kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD ve
            kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit vb.)
            iade edilebilmesi için ambalajlarının açılmamış, denenmemiş,
            bozulmamış ve kullanılmamış olmaları gerekir.
          </p>
          <p className="mb-2">
            9.2. ALICI, şikâyet ve itirazları konusunda başvurularını, aşağıdaki
            kanunda belirtilen parasal sınırlar dâhilinde tüketicinin yerleşim
            yerinin bulunduğu veya tüketici işleminin yapıldığı yerdeki tüketici
            sorunları hakem heyetine veya tüketici mahkemesine yapabilir.
            Parasal sınıra ilişkin bilgiler aşağıdadır:
            <br />
            2024 yılı için tüketici hakem heyetlerine yapılacak başvurularda
            değeri:
            <br />
            a- 11.330 TL’nin (on bir bin üç yüz otuz Türk Lirası) altında
            bulunan uyuşmazlıklarda ilçe tüketici hakem heyetleri yetkilidir.
            <br />
            b- 11.330 TL (on bir bin üç yüz otuz Türk Lirası) üstünde bulunan
            uyuşmazlıklarda büyükşehir statüsündeki illerdeki il tüketici hakem
            heyetleri yetkilidir.
            <br />
            c- Büyükşehir statüsünde olmayan illerde, il merkezlerinde 30.000
            TL'nin altında bulunan uyuşmazlıklarda il tüketici hakem heyetleri
            yetkilidir.
            <br />
            ç- Büyükşehir statüsünde olmayan illere bağlı ilçelerde ise, 11.330
            TL ile 30.000 TL arasındaki uyuşmazlıklarda il tüketici hakem
            heyetleri görevli kabul edilmiştir.
            <br />
            ALICI, işbu sözleşmenin ticari amaçlarla yapıldığını ve yasal
            sınırlar dâhilinde hakem heyetlerine veya mahkemelere başvurma hakkı
            olduğunu kabul eder.
          </p>
        </div>
      </Modal>
      <Modal
        open={modalopen.iptaliade}
        onClose={() => setModalOpen((prev) => ({ ...prev, iptaliade: false }))}
        center
      >
        <div className="mb-4">
          <h2 className="font-bold mb-2">
            TÜKETİCİ HAKLARI - CAYMA - İPTAL İADE KOŞULLARI
          </h2>

          <h3 className="font-semibold mb-2">GENEL:</h3>

          <p className="mb-2">
            1. Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda
            sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu
            ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız.
          </p>

          <p className="mb-2">
            2. Alıcılar, satın aldıkları ürünün satış ve teslimi ile ilgili
            olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli
            Sözleşmeler Yönetmeliği (RG: 27.11.2014/29188) hükümleri ile
            yürürlükteki diğer yasalara tabidir.
          </p>

          <p className="mb-2">
            3. Ürün sevkiyat masrafı olan kargo ücretleri varsa alıcılar
            tarafından ödenecektir.
          </p>

          <p className="mb-2">
            4. Satın alınan her bir ürün, 30 günlük yasal süreyi aşmamak kaydı
            ile alıcının gösterdiği adresteki kişi ve/veya kuruluşa teslim
            edilir. Bu süre içinde ürün teslim edilmez ise, alıcılar sözleşmeyi
            sona erme hakkına sahiptir.
          </p>

          <p className="mb-2">
            5. Satın alınan ürün, eksiksiz ve siparişte belirtilen niteliklere
            uygun ve varsa garanti belgesi, kullanım kılavuzu gibi belgelerle
            teslim edilmek zorundadır.
          </p>

          <p className="mb-2">
            6. Satın alınan ürünün satılmasının imkansızlaşması durumunda,
            satıcı bu durumu öğrendiğinden itibaren 3 gün içinde yazılı olarak
            alıcıya bu durumu bildirmek zorundadır. 14 gün içinde de toplam
            bedel alıcıya iade edilmek zorundadır.
          </p>

          <h3 className="font-semibold mb-2">
            SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:
          </h3>

          <p className="mb-2">
            7. Alıcı, satın aldığı ürün bedelini ödemez veya banka kayıtlarında
            iptal ederse, satıcının ürünü teslim yükümlülüğü sona erer.
          </p>

          <h3 className="font-semibold mb-2">
            KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:
          </h3>

          <p className="mb-2">
            8. Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi
            kartının yetkisiz kişiler tarafından haksız olarak kullanıldığı
            tespit edilirse ve satılan ürün bedeli ilgili banka veya finans
            kuruluşu tarafından satıcıya ödenmez ise, alıcı, sözleşme konusu
            ürünü 3 gün içerisinde nakliye gideri satıcıya ait olacak şekilde
            satıcıya iade etmek zorundadır.
          </p>

          <h3 className="font-semibold mb-2">
            ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE:
          </h3>

          <p className="mb-2">
            9. Satıcının öngöremeyeceği mücbir sebepler oluşursa ve ürün
            süresinde teslim edilemez ise, durum alıcıya bildirilir. Alıcı,
            siparişin iptalini, ürünün benzeri ile değiştirilmesini veya engel
            ortadan kalkana dek teslimatın ertelenmesini talep edebilir. Alıcı
            siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden
            itibaren 14 gün içinde kendisine nakden bu ücret ödenir. Alıcı,
            ödemeyi kredi kartı ile yapmış ise ve iptal ederse, bu iptalden
            itibaren yine 14 gün içinde ürün bedeli bankaya iade edilir, ancak
            bankanın alıcının hesabına 2-3 hafta içerisinde aktarması olasıdır.
          </p>

          <h3 className="font-semibold mb-2">
            ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:
          </h3>

          <p className="mb-2">
            10. Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene
            edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı
            mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan
            mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. Alıcı,
            teslimden sonra mal/hizmeti özenle korunmak zorundadır. Cayma hakkı
            kullanılacaksa mal/hizmet kullanılmamalıdır. Ürünle birlikte fatura
            da iade edilmelidir.
          </p>

          <h3 className="font-semibold mb-2">CAYMA HAKKI:</h3>

          <p className="mb-2">
            11. Alıcı; satın aldığı ürünün kendisine veya gösterdiği adresteki
            kişi/kuruluşa teslim tarihinden itibaren 3 (üç) gün içerisinde,
            satıcıya aşağıdaki iletişim bilgileri üzerinden bildirmek şartıyla
            Ön Bilgilendirme Formundaki 8.ci maddedeki CAYMA HAKKI kurallarını
            kapsıyorsa iade etme hakkına sahiptir.
          </p>

          <p className="mb-2">
            12. Satıcının cayma hakkı bildirimi yapılacak iletişim bilgileri:
          </p>
          <p className="ml-4">
            Şirket Adı/Unvanı: MAYPLASTİK
            <br />
            Adres: Tatlıcak Mahallesi Gürçınar Sokak No:60 Vatan Sanayi Sitesi
            Karatay - KONYA
            <br />
            E-posta: bilgi@mayplastik.com
            <br />
            Tel: 0 332 342 16 66
          </p>

          <h3 className="font-semibold mb-2">CAYMA HAKKININ SÜRESİ:</h3>

          <p className="mb-2">
            13. Alıcı, satın aldığı eğer bir hizmet ise, bu 3 günlük süre
            sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi
            sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan
            hizmet sözleşmelerinde cayma hakkı kullanılamaz.
          </p>

          <p className="mb-2">
            14. Cayma hakkının kullanımından kaynaklanan masraflar satıcıya
            aittir.
          </p>

          <p className="mb-2">
            15. Cayma hakkının kullanılması için 3 (üç) günlük süre içinde
            satıcıya iadeli taahhütlü posta, faks veya e-posta ile yazılı
            bildirimde veya telefonla iletişimde bulunulması ve ürünün Ön
            Bilgilendirme Formundaki "CAYMA HAKKI" kurallarını kapsaması
            şarttır.
          </p>

          <h3 className="font-semibold mb-2">CAYMA HAKKININ KULLANIMI:</h3>

          <p className="mb-2">
            16. Üçüncü kişiye veya alıcıya teslim edilen ürünün faturası, (iade
            edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun
            düzenlemiş olduğu iade faturası ile birlikte gönderilmesi
            gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri,
            iade faturası kesilmediği takdirde tamamlanamayacaktır.)
          </p>

          <p className="mb-2">
            17. İade formu, iade edilecek ürünlerin kutusu, ambalajı, varsa
            standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak
            teslim edilmesi gerekmektedir.
          </p>

          <h3 className="font-semibold mb-2">İADE KOŞULLARI:</h3>

          <p className="mb-2">
            18. Satıcı, cayma bildiriminin kendisine ulaşmasından itibaren en
            geç 10 günlük süre içerisinde toplam bedeli ve alıcıyı borç altına
            sokan belgeleri alıcıya iade etmek ve 20 günlük süre içerisinde malı
            iade almakla yükümlüdür.
          </p>

          <p className="mb-2">
            19. Alıcının kusurundan kaynaklanan bir nedenle malın değerinde bir
            azalma olursa veya iade imkânsızlaşırsa alıcı, kusuru oranında
            satıcının zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı
            süresi içinde malın veya ürünün usulüne uygun kullanılması sebebiyle
            meydana gelen değişiklik ve bozulmalardan alıcı sorumlu değildir.
          </p>

          <p className="mb-2">
            20. Cayma hakkının kullanılması nedeniyle satıcı tarafından
            düzenlenen kampanya limit tutarının altına düşülmesi halinde
            kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
          </p>

          <h3 className="font-semibold mb-2">
            CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER:
          </h3>

          <p className="mb-2">
            21. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile
            hizmetin ifasına başlanan hizmet sözleşmeleri.
          </p>

          <p className="mb-2">
            22. Fiyatı borsa veya teşkilatlanmış diğer piyasalarda belirlenen
            mallara ilişkin sözleşmeler.
          </p>

          <p className="mb-2">
            23. Alıcının isteği veya açıkça onun kişisel ihtiyaçları
            doğrultusunda hazırlanan mallar, hızlı bozulan veya son kullanma
            tarihi geçme ihtimali olan malların iadesi kabul edilmemektedir.
          </p>

          <p className="mb-2">
            24. Ambalajının açılmasından sonra iadesi sağlık ve hijyen açısından
            uygun olmayan ürünler, tek kullanımlık ürünler, kopyalanabilir
            yazılım ve programlar, hızlı bozulan veya son kullanma tarihi geçme
            ihtimali olan mallar, tekrar kullanılabilirlikten çıkmış ürünlerin
            iadesi kabul edilmemektedir.
          </p>

          <p className="mb-2">
            25. Niteliği itibariyle iade edilemeyecek ürünler; tek kullanımlık
            ürünler, kopyalanabilir yazılım ve programlar, hızlı bozulan veya
            son kullanma tarihi geçme ihtimali olan malların iadesi kabul
            edilmemektedir.
          </p>

          <p className="mb-2">
            26. Cayma hakkı süresi sona ermeden önce, alıcının onayı ile ifasına
            başlanan hizmetlere ilişkin sözleşmeler.
          </p>

          <p className="mb-2">
            27. Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye
            anında teslim edilen gayrimaddi mallara ilişkin sözleşmelerde cayma
            hakkı kullanılamaz.
          </p>

          <p className="mb-2">
            28. Ayrıca, niteliği itibarıyla iade edilemeyecek ürünler (altın,
            gümüş vb. kıymetli madenler), ambalajı açılan, kopyalanabilir
            yazılım, kopyalanabilir yazılımlar, bilgisayar sarf malzemeleri,
            kopyalanabilir her türlü müzik ve video içerikleri ile CD ve
            DVD’ler, mobil telefon kontörleri, kullanılan, yıkanmış, üzerinde
            etiket ya da barkodu bulunmayan, altın, gümüş, pırlanta ve değerli
            taşlar, özel sipariş üzerine üretilmiş ve/veya ithal edilmiş
            ürünler, ürünle birlikte verilen faturası olmayanlar, asgari bir
            standarda göre tüketiciye sunulan ürünler, (indirimli, ikinci el vb.
            ürünler) ile kampanyalı veya promosyonlu satışlarda cayma hakkı
            kullanılamaz.
          </p>

          <h3 className="font-semibold mb-2">İPTAL VE İADE KOŞULLARI:</h3>

          <p className="mb-2">
            29. Elektronik ortamda yapılan iptal talepleri 14 gün içinde iade
            işlemi yapılacaktır. Ancak cayma hakkının kullanılması halinde,
            mal/hizmetin alıcıya gönderim masrafları alıcıya aittir.
          </p>

          <p className="mb-2">
            30. Alıcı, siparişinin iptal edilmesini veya siparişin iptal
            edilmesi durumunda bedelin iade edilmesini talep etmesi halinde;
            siparişin iptal edildiği tarihten itibaren, sipariş bedeli 14 gün
            içinde alıcıya geri ödenir. Ancak cayma hakkı kullanıldığı
            durumlarda, mal/hizmetin iade edilmesi sırasında kargo ücreti
            alıcıya ait olur.
          </p>
        </div>
      </Modal>
      <Modal
        open={modalopen.iletisim}
        onClose={() => setModalOpen((prev) => ({ ...prev, iletisim: false }))}
        center
      >
        <div className="pt-2 px-2">
          <h2 className="text-xl mb-2 font-bold">İLETİŞİM</h2>
          <div className="text-sm mb-2">
            TELEFON :{" "}
            <span className="text-sm font-semibold">0 332 342 16 66</span>
          </div>
          <div className="text-sm mb-2">
            GSM : <span className="text-sm font-semibold">0 544 942 42 82</span>
          </div>
          <div className="text-sm mb-2">
            WHATSAPP :{" "}
            <span className="text-sm font-semibold">0 544 942 42 82</span>
          </div>
          <div className="text-sm mb-2">
            E-POSTA :{" "}
            <span className="text-sm font-semibold">bilgi@mayplastik.com</span>
          </div>
          <div className="text-sm mb-2">
            ADRES :{" "}
            <span className="text-sm font-semibold">
              Tatlıcak Mahallesi Gürçınar Sokak No:60 Vatan Sanayi Sitesi
              Karatay - KONYA
            </span>
          </div>
          <div>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3149.532420402536!2d32.569229899999996!3d37.8712297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d09b9463eaec29%3A0x784b49ad6f487584!2sMAYPLAST%C4%B0K%20ROTASYON%20BUZA%C4%9EI%20KUL%C3%9CBES%C4%B0%20SU%20DEPOSU%20DONMAZ%20SULUK%20LED%20I%C5%9EIKLI%20%C3%9CR%C3%9CNLER!5e0!3m2!1sen!2str!4v1724072327365!5m2!1sen!2str"
              width="600"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </Modal>
      <div className="w-full my-2">
        <p className="text-sm font-semibold text-center">
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() =>
              setModalOpen((prev) => ({ ...prev, gizlilik: true }))
            }
          >
            Gizlilik ve Güvenlik Politikası
          </span>{" "}
          |{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() =>
              setModalOpen((prev) => ({ ...prev, bilgilendirme: true }))
            }
          >
            Ön Bilgilendirme Formu
          </span>{" "}
          |{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() =>
              setModalOpen((prev) => ({ ...prev, iptaliade: true }))
            }
          >
            İptal ve İade Politikası
          </span>{" "}
          |{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() =>
              setModalOpen((prev) => ({ ...prev, iletisim: true }))
            }
          >
            İletişim
          </span>
        </p>
      </div>
    </>
  );
};

export default ModalPage;
